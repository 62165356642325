import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "AirPods With Alfred Workflow Shortcut",
  "author": "tzookb",
  "type": "post",
  "date": "2019-07-24T13:50:08.000Z",
  "url": "/2019/07/airpods-with-alfred-workflow-shortcut/",
  "medium_post": ["O:11:\"Medium_Post\":11:{s:16:\"author_image_url\";N;s:10:\"author_url\";N;s:11:\"byline_name\";N;s:12:\"byline_email\";N;s:10:\"cross_link\";N;s:2:\"id\";N;s:21:\"follower_notification\";N;s:7:\"license\";N;s:14:\"publication_id\";N;s:6:\"status\";N;s:3:\"url\";N;}"],
  "categories": ["How To"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` I'm a huge fan of Alfred app! newPAPP This app saves me a huge pile of time and just make my mac life much better. So after I got my shortcut for connecting my airpods with a cli command, I got the idea of why not just adding it to Alfred?! I spend most of my time with terminals, but there are times I dont use them and why the hell should I open a terminal for connecting to my airpods So first, please follow this blog post: `}<a href="https://tzookb.com/2019/07/create-a-connect-airpods-cli-command/" target="_blank" rel="noreferrer noopener">{`Create a Connect AirPods CLI Shortcut`}</a>{` You can skip the last part of creating an alias as we will be using Alfred instead. ** you should have an Alfred license to use workflows `}</p>
    <ul>
      <li parentName="ul">{`Open your alfred settings, click on workflows and sign`}</li>
    </ul>
    <undefined><img {...{
        "src": "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.31.19-1024x630.png",
        "alt": "",
        "className": "wp-image-426",
        "srcSet": ["https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.31.19-1024x630.png 1024w", "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.31.19-300x185.png 300w", "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.31.19-768x472.png 768w"],
        "sizes": "(max-width: 1024px) 100vw, 1024px"
      }}></img>{` `}</undefined>
    <ul>
      <li parentName="ul">{`create a blank Workflow `}</li>
    </ul>
    <undefined><img {...{
        "src": "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.31.48.png",
        "alt": "",
        "className": "wp-image-427",
        "srcSet": ["https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.31.48.png 688w", "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.31.48-300x222.png 300w"],
        "sizes": "(max-width: 688px) 100vw, 688px"
      }}></img>{` `}</undefined>
    <ul>
      <li parentName="ul">{`Fill in your title and description (will appear on alfred)`}</li>
      <li parentName="ul">{`you can add an image to make it nicer (`}<a parentName="li" {...{
          "href": "https://www.google.com/search?safe=active&biw=1152&bih=613&tbm=isch&sa=1&ei=iGA4XYVxiuBr75Gi6A8&q=airpods+png&oq=airpods+png&gs_l=img.3..0i67j0l6j0i7i30l3.3155.3155..4081...0.0..0.127.127.0j1......0....1..gws-wiz-img.zyaZg5rNwI8&ved=0ahUKEwiF1c33183jAhUK8BoKHe-ICP0Q4dUDCAY&uact=5"
        }}>{`here`}</a>{`)`}</li>
    </ul>
    <undefined><img {...{
        "src": "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.32.27-1024x712.png",
        "alt": "",
        "className": "wp-image-428",
        "srcSet": ["https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.32.27-1024x712.png 1024w", "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.32.27-300x209.png 300w", "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.32.27-768x534.png 768w", "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.32.27.png 1292w"],
        "sizes": "(max-width: 1024px) 100vw, 1024px"
      }}></img>{` `}</undefined>
    <ul>
      <li parentName="ul">{`right click on the black canvas and select Input  keyword. `}</li>
      <li parentName="ul">{`Type keyword that will pop the workflow in Alfred`}</li>
    </ul>
    <undefined><img {...{
        "src": "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.33.44.png",
        "alt": "",
        "className": "wp-image-429",
        "srcSet": ["https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.33.44.png 916w", "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.33.44-300x209.png 300w", "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.33.44-768x535.png 768w"],
        "sizes": "(max-width: 916px) 100vw, 916px"
      }}></img>{` `}</undefined>
    <ul>
      <li parentName="ul">{`Right click again and select Run script`}</li>
    </ul>
    <undefined><img {...{
        "src": "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.34.27.png",
        "alt": "",
        "className": "wp-image-430",
        "srcSet": ["https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.34.27.png 808w", "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.34.27-283x300.png 283w", "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.34.27-768x814.png 768w"],
        "sizes": "(max-width: 808px) 100vw, 808px"
      }}></img>{` `}</undefined>
    <ul>
      <li parentName="ul">{`paste the script for your airpods`}</li>
      <li parentName="ul">{`You should follow `}<a rel="noreferrer noopener" aria-label="this post (opens in a new tab)" href="https://tzookb.com/2019/07/create-a-connect-airpods-cli-command/" target="_blank">{`this post`}</a>{` to get your own command`}</li>
      <li parentName="ul">{`be sure to have full path for bluetoothconnector`}</li>
    </ul>
    <img {...{
      "src": "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.35.21-1024x194.png",
      "alt": "",
      "className": "wp-image-431",
      "srcSet": ["https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.35.21-1024x194.png 1024w", "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.35.21-300x57.png 300w", "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.35.21-768x146.png 768w", "https://tzookb.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-24-at-16.35.21.png 1580w"],
      "sizes": "(max-width: 1024px) 100vw, 1024px"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      